<template>
  <div style="">
    <!-- 导航 -->
    <div style="" class="header">
      <van-nav-bar
        title="新闻"
        left-text=""
        left-arrow
        safe-area-inset-top
        @click-left="onClickLeft"
      >
        <template #right> </template>
      </van-nav-bar>
      <van-tabs v-model="tabCur">
        <van-tab
          v-for="(item, index) in tabList"
          :title="item.value"
          :key="index"
        >
        </van-tab>
      </van-tabs>
      <div class="no-news" v-if="!list.length">
         <img style="width: 50%; height: 20%" src="../../assets/缺省页_暂无缴费@2x.png"/>
         <span>暂无新闻</span>
      </div>
    </div>

    <!-- 内容 -->
    <van-pull-refresh v-model="refreshLoading" @refresh="onRefresh" loosing-text="松开即可刷新...">
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoad"
      >
        <div v-if="list.length" class="lists" style="margin-top: 92px">
          <ul class="list" v-for="(i, n) in list" :key="n">
            <a
              class="list-item"
              :href="i.url"
              style="color: black"
              v-if="i.type == 'news'"
            >
              <img :src="i.thumbnail_pic_s" alt="" v-show="i.thumbnail_pic_s" />
              <div class="content">
                <span>{{ i.title }}</span>
                <div class="container">
                  <span>热门</span>
                  <span style="color: #5f5f5f; font-size: 12px">{{
                    i.date.split(" ")[0]
                  }}</span>
                </div>
              </div> 
            </a>
            <div v-else>
              <a
                class="list-item"
                :href="i.target == 3 ? i.androidDownloadUrl : i.localPageUrl"
                style="color: black"
                v-if="android"
              >
                <div>名称</div>
                广告
              </a>
              <a
                class="list-item"
                :href="i.target == 3 ? i.iosDownloadUrl : i.localPageUrl"
                style="color: black; height: 120px"
                v-else
              >
                <div style="width: 94%">
                  <img
                    :src="i.materialPicUrl"
                    alt=""
                    style="width: 100%; height: 50px"
                  />
                  <div style="margin-left: 2%">{{ i.name }}</div>
                  <span style="margin-left: 2%; color: #c2c2c2; font-size: 12px"
                    >广告</span
                  >
                </div>
              </a>
            </div>
          </ul>
        </div>
      </van-list>
    </van-pull-refresh>
  </div>
</template>
<script>
import { getList } from "@/api/news"
export default {
  name: "news",
  data() {
    return {
      tabList: [
        {
          value: "头条",
          key: "0",
          type: "top",
        },
        {
          value: "国内",
          key: "1",
          type: "guonei",
        },
        {
          value: "国际",
          key: "2",
          type: "guoji",
        },
        {
          value: "娱乐",
          key: "3",
          type: "yule",
        },
        {
          value: "体育",
          key: "4",
          type: "tiyu",
        },
        {
          value: "军事",
          key: "5",
          type: "junshi",
        },
        {
          value: "科技",
          key: "6",
          type: "keji",
        },
        {
          value: "财经",
          key: "7",
          type: "caijing",
        },
        {
          value: "游戏",
          key: "8",
          type: "youxi",
        },
        {
          value: "汽车",
          key: "9",
          type: "qiche",
        },
        {
          value: "健康",
          key: "10",
          type: "jiankang",
        },
      ],
      tabCur: 0,
      query: {
        isFilter: 0,
        page: 1,
        pageSize: 10,
        type: "top",
      },
      list: [],
      loading: false,
      finished: false,
      refreshLoading: false,
      refreshBoolean: false
    };
  },

  watch: {
    tabCur: {
      handler(value) {
        this.query.type = this.tabList[value].type;
        this.query.page = 1
        this.refreshBoolean = true
        this.getpage()
      },
      deep: true,
    },
  },

  created() {
    this.$store.commit('setSession', this.$route.query.session || '$2a$10$DU2viBv.JPAQPxTY8Faweexu8dUNGGVa8iPNb.8QwYlQHlau6S9Iu')
  },
  
  methods: {
    onClickLeft() {
      let u = navigator.userAgent;
      let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1; //android
      let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
      if (isIOS) {
        window.webkit.messageHandlers.close.postMessage("up");
      }
      if (isAndroid) {
        window.android.close();
      }
    },

    tabSelect(e) {
      this.query.type = e.currentTarget.dataset.type;
      this.tabCur = e.currentTarget.dataset.id;
      this.list = [];
      this.getpage();
    },

    getpage() {
      this.$GeShowLoading()
      getList(this.query).then((res) => {
        this.refreshLoading = false
        this.loading = false
        if (res.data.code === 0) {
          if (!this.refreshBoolean) {
            this.list = [...this.list, ...res.data.data]
          } else {
            this.list = res.data.data
          }
          this.refreshBoolean = false
        } else {
          this.finished = true
        }
        this.$GeHideLoading()
      })
    },

    onLoad () {
      this.query.page ++
      this.getpage()
    },

    onRefresh () {
      this.query.page = 1
      this.finished = false
      this.refreshBoolean = true
      this.getpage()
    }
  },
};
</script>
<style lang="less">
#app {
  background-color: #efefef;
}

.header {
  position: fixed;
  width: 100%;
  height: 100px;
  min-height: 60px;
  line-height: 60px;
  z-index: 999;
  position: fixed;
  top: 0;
}
.no-news {
  display: flex;
  flex-direction: column;
  margin-top: 50px;
  margin-left: 30%;
  span{
    margin-left: 13%;
  }
}
.flex {
  display: flex;
}

.tab::-webkit-scrollbar {
  display: none;
}

.tab .text-center span {
  font-size: 16px;
  display: inline-block;
  margin: 0 12px;
  height: 38px;
}
/* .lists {

} */
.list {
  color: black;
}
.van-list {
  margin-top: 100px;
}

.list-item {
  display: flex;
  width: 94%;
  margin: 10px 3%;
  background-color: white;
  // height: 134px;
  border-radius: 8px;
}

.list-item img {
  width: 114px;
  height: 114px;
  margin: 10px;
}

.list-item .content {
  flex: 1;
  margin: 10px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

</style>
